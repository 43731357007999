import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { Severty, ShowToast } from "../../../helper/toast";
import { PRICE } from "../../../constants/conts";
const { Title } = Typography;
const { Option } = Select;
class ExamFeeConstant {
  static MINIMUM_EXAM_CHARGE_BOOKNOW = 100;
  static BOOKFEE_WITHOUT_PAYFAST_THRESHOLD = 840;
  static PAY_FAST_OFFSET_CHARGE_EXTRA = 2;
  static PAY_FAST_CHARGE = 1.0363;
  static PAY_FAST_PERCENTAGE = 3.2;
  static BOOKNOW_CHARGE = 1.05;
}
const AddEditSyllabusOpt = ({ show, hide, data ,refresh}) => {
  const { request } = useRequest();
  const {
    centerId,
    courseId,
    examConductsId,
    examId,
    syllabusId,
    syllabusOptionId,
  } = data;
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState("");
  const [priceSuggestionValue, setPriceSuggestionValue] = useState("1");
  const [form] = Form.useForm();


  useEffect(() => {
    if (!syllabusOptionId) return;
    request({
      url: `/admin/center/allCourseWithPrice/${syllabusOptionId}/${centerId}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          form.setFieldsValue({...data.data,totalSeats : data.data?.availableSeat});
          setEditId(data?.data?._id);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  }, [syllabusOptionId]);

  const onFinish = (value) => {
    if (!syllabusOptionId) return;
    console.log(value);
    const payload = {
      ...value,
      centerId,
      courseId,
      examConductsId,
      examId,
      syllabusId,
      syllabusOptionId,
    };

    request({
      url: `/admin/center/allCourseWithPrice${editId ? `/${editId}` : ""}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);    
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields();
          refresh()
          hide()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  const computeBasedOnBookingFee = (bookingFee) => {
    let bookingFeeWithoutPayFast = (bookingFee - ExamFeeConstant.PAY_FAST_OFFSET_CHARGE_EXTRA) / ExamFeeConstant.PAY_FAST_CHARGE;
    bookingFeeWithoutPayFast = Math.round(bookingFeeWithoutPayFast * 100) / 100;
    let payFastFee = bookingFee - bookingFeeWithoutPayFast;
    payFastFee = Math.round(payFastFee * 100) / 100;
    let baseCenterAmount = bookingFeeWithoutPayFast - ExamFeeConstant.MINIMUM_EXAM_CHARGE_BOOKNOW;
    baseCenterAmount = Math.max(0, Math.round(baseCenterAmount * 100) / 100);
    return {
      bookingPayFastFeeAmount: parseFloat(payFastFee.toFixed(2)),
      bookingBooknowAmount: ExamFeeConstant.MINIMUM_EXAM_CHARGE_BOOKNOW,
      bookingCenterAmount: baseCenterAmount
    };
  }


  const computeBasedOnCenterFee = (centerAmount) => {
    console.log(centerAmount)
    let booknowCharge = ExamFeeConstant.MINIMUM_EXAM_CHARGE_BOOKNOW;

    let payFastCharge = percentage(ExamFeeConstant.PAY_FAST_PERCENTAGE, (centerAmount + booknowCharge)) + ExamFeeConstant.PAY_FAST_OFFSET_CHARGE_EXTRA;
    payFastCharge = Math.round(payFastCharge * 100) / 100;

    let bookingFee = centerAmount + booknowCharge + payFastCharge;
    bookingFee = Math.round(bookingFee * 100) / 100;
    return {
      bookingPayFastFeeAmount: payFastCharge,
      bookingBooknowAmount: booknowCharge,
      bookingCenterAmount: bookingFee
    };
  }

  const percentage = (percent, total) => {
    return ((percent / 100) * total);
  }
  const calculateNormalPrice = () => {
    const values = form.getFieldsValue();
    const bookingFee = values.normal.bookingFee;
    const lateBookingFee = values.late.bookingFee;
    const superLateBookingFee = values.superLate.bookingFee;
    const youGet = values.normal.youGet;
    const lateyouGet = values.late.youGet;
    const superLateyouGet = values.superLate.youGet;

    if (priceSuggestionValue === "1") {
      if (bookingFee) {
        const computedValues = computeBasedOnBookingFee(bookingFee);
        form.setFieldsValue({
          normal: {
            youGet: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
          },
        });
      }
      if (superLateBookingFee) {
        const computedValues = computeBasedOnBookingFee(superLateBookingFee);
        form.setFieldsValue({
          superLate: {
            youGet: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
          },
        });
      }
      if (lateBookingFee) {
        const computedValues = computeBasedOnBookingFee(lateBookingFee);
        form.setFieldsValue({
          late: {
            youGet: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
          },
        });
      }
    }
    else {
      if (youGet) {
        const computedValues = computeBasedOnCenterFee(youGet);
        form.setFieldsValue({
          normal: {
            bookingFee: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
          },
        });
      }
      if (lateyouGet) {
        const computedValues = computeBasedOnCenterFee(lateyouGet);
        form.setFieldsValue({
          late: {
            bookingFee: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
          },
        });
      }
      if (superLateyouGet) {
        const computedValues = computeBasedOnCenterFee(superLateyouGet);
        form.setFieldsValue({
          superLate: {
            bookingFee: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
          },
        });
      }
    }
  };
  const priceSuggestion = (value) => {
    setPriceSuggestionValue(value)
  }
  return (
    <div>
      <Modal
        open={show}
        width={800}
        title={`Syllabus Option Code Seat Configuration`}
        okText="Ok"
        onCancel={ () => hide()}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          //loading: loading,
        }}
      >
        <Title level={4}>{data.courseName}</Title>
        <Title level={5} className="cap">
          {data.syllabusName}/{data.syllabusOptName}
        </Title>
        <Form
          id="create"
          form={form}
          className="px-3"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[8, 0]}>
            {/* <Col span={12}>
              <Form.Item
                className="mb-0"
                label="Centre Code"
                name="centerCode"
                help="Your own code for this subject exam."
                rules={[
                  { required: true, message: "Centre Code is mandatory" },
                ]}
                normalize={(value) => value.trimStart()}
              >
                <Input />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                className="mb-0"
                label="Total Seats"
                name="totalSeats"
                help="Seats for this subject option code"
                rules={[
                  {
                    required: true,
                    message: "Total Seats should be non zero value.",
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label="Select the Pricing suggestion"
                name="priceSuggestion"
                help="We will show the final prices and charges applied."
                rules={[
                  {
                    required: true,
                    message: "Centre Code is mandatory ",
                  },
                ]}
              >
                <Select
                  defaultValue="1"
                  placeholder="Suggest the price you want the guardians to see?"
                  allowClear
                  onChange={priceSuggestion}
                >
                  <Option value="1">
                    Suggest the price you want the guardians to see?
                  </Option>
                  <Option value="2">
                    Suggest the amount you as a centre want to receive?
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Normal booking start */}
            <Col span={24}>
              <Title level={4} className="border-2 border-bottom">
                Normal Booking
              </Title>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Enter the Booking Fee (${PRICE})`}
                name={["normal", "bookingFee"]}
                rules={[{ required: true, message: "Normal Booking Fee is mandatory" }]}
              >
                <InputNumber onChange={calculateNormalPrice} disabled={priceSuggestionValue === "1" ? false : true} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`You Get (${PRICE})`}
                name={["normal", "youGet"]}
                rules={[{ required: true, message: "Centre Amount is mandatory" }]}
              >
                <InputNumber onChange={calculateNormalPrice} min={0} disabled={priceSuggestionValue === "2" ? false : true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Book Now Fee (${PRICE})`}
                name={["normal", "bookingNow"]}
              >
                <InputNumber min={0} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Pay Fast Fee (${PRICE})`}
                name={["normal", "payFast"]}
              >
                <InputNumber min={0} disabled />
              </Form.Item>
            </Col>
            {/* Normal booking end */}
            {/* {Late booking start} */}

            <Col span={24}>
              <Title level={4} className="border-2 border-bottom">
                Late Booking
              </Title>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Enter the Booking Fee (${PRICE})`}
                name={["late", "bookingFee"]}
                rules={[{ required: true, message: "Normal Booking Fee is mandatory" }]}
              >
                <InputNumber onChange={calculateNormalPrice} disabled={priceSuggestionValue === "1" ? false : true} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`You Get (${PRICE})`}
                name={["late", "youGet"]}
                rules={[{ required: true, message: "Centre Amount is mandatory" }]}
              >
                <InputNumber onChange={calculateNormalPrice} disabled={priceSuggestionValue === "2" ? false : true} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Book Now Fee (${PRICE})`}
                name={["late", "bookingNow"]}
              >
                <InputNumber min={0} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Pay Fast Fee (${PRICE})`}
                name={["late", "payFast"]}
              >
                <InputNumber min={0} disabled />
              </Form.Item>
            </Col>

            {/* Late booking end */}

            {/* {Super Late booking start} */}

            <Col span={24}>
              <Title level={4} className="border-2 border-bottom">
                Super Late Booking
              </Title>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Enter the Booking Fee (${PRICE})`}
                name={["superLate", "bookingFee"]}
                rules={[{ required: true, message: "Normal Booking Fee is mandatory" }]}
              >
                <InputNumber onChange={calculateNormalPrice} disabled={priceSuggestionValue === "1" ? false : true} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`You Get (${PRICE})`}
                name={["superLate", "youGet"]}
                rules={[{ required: true, message: "Centre Amount is mandatory" }]}
              >
                <InputNumber onChange={calculateNormalPrice} disabled={priceSuggestionValue === "2" ? false : true} min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Book Now Fee (${PRICE})`}
                name={["superLate", "bookingNow"]}
              >
                <InputNumber min={0} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="mb-0"
                label={`Pay Fast Fee (${PRICE})`}
                name={["superLate", "payFast"]}
              >
                <InputNumber min={0} disabled />
              </Form.Item>
            </Col>
            {/*Super Late booking end */}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default AddEditSyllabusOpt;
