let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/booknow/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "http://localhost:4510/api/";
} else {
  // URL = "http://103.189.173.7:4510/api/";  
  URL = "https://api.booknow.academy/api/";
  // URL = "http://localhost:4510/api/";

}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard/dashboard-data",
  listOrder: "admin/order/list",
  viewOrder: "admin/order/view",
  statusOrder: "admin/order/status",
  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",
  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",
  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",
  updateSetting: "admin/auth/update-setting",
  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",
  // SubAdmin APIs
  listSubAdmin: "admin/sub-admin/list",
  addEditSubAdmin: "admin/sub-admin/add-edit",
  statusSubAdmin: "admin/sub-admin/status",
  viewSubAdmin: "admin/sub-admin/view",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",
  // Guardian APIs
  listGuardian: "admin/guardian/list",
  addEditGuardian: "admin/guardian/add-edit",
  statusGuardian: "admin/guardian/status",
  deleteGuardian: "admin/guardian/delete",
  viewGuardian: "admin/guardian/view",
  importGuardian: "admin/guardian/import-file",
  // Student APIs
  listStudent: "admin/student/list",
  addEditStudent: "admin/student/add-edit",
  statusStudent: "admin/student/status",
  deleteStudent: "admin/student/delete",
  viewStudent: "admin/student/view",
  importStudent: "admin/student/import-file",
  // Course APIs
  listCourse: "admin/course/list",
  addEditCourse: "admin/course/add-edit",
  statusCourse: "admin/course/status",
  viewCourse: "admin/course/view",
  // Center APIs
  listCenter: "admin/center/list",
  addEditCenter: "admin/center/add-edit",
  statusCenter: "admin/center/status",
  viewCenter: "admin/center/view",
  // Event APIs
  listEvent: "admin/event/list",
  addEditEvent: "admin/event/add-edit",
  // addEditEvent: "admin/event/add-edit",
  statusEvent: "admin/event/status",
  viewEvent: "admin/event/view",
  // Preparation APIs
  listPreparation: "admin/preparation/list",
  addEditPreparation: "admin/preparation/add-edit",
  statusPreparation: "admin/preparation/status",
  // Exam APIs
  listExam: "admin/exam/list",
  addEditExam: "admin/exam/add-edit",
  statusExam: "admin/exam/status",
  viewExam: "admin/exam/view",

  //Exam Board API
  listExamBoard: "admin/examboard/list",
  addEditExamBoard: "admin/examboard/add-edit",
  statusExamBoard: "admin/examboard/status",


  // Exam Conduct APIs
  listExamConduct: "admin/exam-conduct/list",
  addEditExamConduct: "admin/exam-conduct/add-edit",
  statusExamConduct: "admin/exam-conduct/status",
  viewExamConduct: "admin/exam-conduct/view",
  listAllExams: "admin/exam-conduct/exam-list",
  ViewExamConduct: "admin/exam-conduct/center-exam-details",


  // Syllabus APIs
  listSyllabus: "admin/syllabus/list",
  addEditSyllabus: "admin/syllabus/add-edit",
  statusSyllabus: "admin/syllabus/status",
  viewSyllabus: "admin/syllabus/view",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog/list",
  addEditBlog: "admin/blog/add-edit",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",

  // Banner APIs
  listBanner: "admin/banner/list",
  addEditBanner: "admin/banner/add-edit",
  statusBanner: "admin/banner/status",
  viewBanner: "admin/banner/view",

  // Content APIs
  listContent: "admin/content/list",
  addEditContent: "admin/content/add-edit",
  statusContent: "admin/content/status",
  viewContent: "admin/content/view",

  // Syllabus Option Api
  addSyllabusOption: "center/syllabusOption/add",
  statusSyllabusOption: "center/syllabusOption/status",
  listingSyllabusOption: "center/syllabusOption/list",
  //all course details APIs
  getCourseDetails: "admin/center/allCourseDetails",

  // Common Routes
  getSetting: "common/setting",
  getAllSyallbus: "common/all-syallbus",
  carMakeList: "common/make",
  carModelList: "common/model",
  categoryList: "common/category",
  brandList: "common/brand",
  productList: "common/product",
  getAppSetting: "common/app-setting",
  activity: "common/user-activity",
  dealerSpecialist: "common/dealer-specialist",
  getCenters: "common/centers",
  getExamBoard: "common/get-exam-board",
  syllabusOption: "common/get-syallbus-option",
  getCourseAll: "common/course",
  allCentre: "/common/centers",
  allExam : "/common/all-exam",

  // Size APIs
  size: "admin/size",
};

export default apiPath;
