import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Tag,
  Select,
  Tooltip,
  message,
  InputNumber,
  Image,
  Switch,
  DatePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import ShowTotal from "../../components/ShowTotal";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import apiPath from "../../constants/apiPath";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import { shortLang, longLang } from "../../config/language";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import ImportForm from "../../components/ImportForm";
import AddFrom from "../ExamConduct/Add";
import { PRICE, eventCategory, eventMode, eventType } from "../../constants/conts";
import LocationMap from "../User/LocationMap";
import DescriptionEditor from "../../components/DescriptionEditor";
import { useNavigate, useParams } from "react-router";
const Search = Input.Search;
class ExamFeeConstant {
  static MINIMUM_EXAM_CHARGE_BOOKNOW = 50;
  static BOOKFEE_WITHOUT_PAYFAST_THRESHOLD = 840;
  static PAY_FAST_OFFSET_CHARGE_EXTRA = 2;
  static PAY_FAST_CHARGE = 1.0363;
  static PAY_FAST_PERCENTAGE = 3.2;
  static BOOKNOW_CHARGE = 1.05;
}
function Index() {
  const sectionName = "Exam Preparation";
  const routeName = "examconduct";


  const api = {
    status: apiPath.statusEvent,
    addEdit: apiPath.addEditEvent,
    view: apiPath.viewEvent,
    list: apiPath.listCenter,
  };

  const { request } = useRequest();
  const [center, setCenter] = useState();
  const [course, setCourse] = useState();
  const [centerList, setCenterList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [courseData, setCourseData] = useState([])
  const [syllabusData, setSyllabusData] = useState([])
  const [form] = Form.useForm();
  const [cityId, setCityId] = useState();
  const [latLong, setlatLong] = useState({});
  const [userAddress, setUserAddress] = useState(null);
  const [file, setFile] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [image, setImage] = useState([]);
  const [tnc, setTnc] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [editorValue2, setEditorValue2] = useState('');
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventStartDate, setEventStartDate] = useState(null);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [data, setData] = useState({});
  const [priceSuggestionValue, setPriceSuggestionValue] = useState("2");

  const disabledBookingStartDate = (current) => {
    return (
      current &&
      (current < moment().startOf("day") || current >= eventStartDate)
    );
  };

  const disabledBookingEndDate = (current) => {
    return (
      current && (current <= bookingStartDate || current >= eventStartDate)
    );
  };

  const disabledEventEndDate = (current) => {
    return current && current <= eventStartDate;
  };
  const getAllCourse = () => {
    request({
      url: "/common/course",
      method: 'GET',
      onSuccess: (data) => {
        console.log(data.data, "data")
        setCourseData(data.data.list);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const handleEditorChange = (data) => {
    setEditorValue(data);
  }

  useEffect(() => {
    getAllCourse()
  }, [])

  const handleImage = (data) => {
    setImage(data);
    setFile(data[0]?.url);
  }

  const handleIsFreeChange = (checked) => {
    setIsFree(checked);
  };

  const handleTncChange = (checked) => {
    setTnc(checked);
  };

  const handleEditorChange2 = (data) => {
    setEditorValue2(data);
  }
  const handleLocationChange = (value) => {
    setUserAddress(value.address);
    setlatLong({
      lat: parseFloat(value.latitude),
      lng: parseFloat(value.longitude),
    });
    setCityId(value.city);
    console.log(value)
  };
  const getAllSyallbus = () => {
    request({
      url: `/common/syllabus-data/${course}`,
      method: 'GET',
      onSuccess: (data) => {
        console.log(data.data, "data")
        setSyllabusData(data.data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getEditData = () => {
    request({
      url: api.view + `/${id}`,
      method: 'GET',
      onSuccess: (data) => {
        console.log(data, "line141")
        setData(data.data)
        setEditorValue(data.data.description)
        form.setFieldsValue({
          ...data?.data, center_id: data?.data?.center_id?.name, event_start_date: moment(data?.data?.event_start_date),
          event_end_date: moment(data?.data?.event_end_date),
          booking_start_date: moment(data?.data?.booking_start_date),
          booking_end_date: moment(data?.data?.booking_end_date),
        })
        setEventStartDate(moment(data?.data?.event_start_date));
        setEventEndDate(moment(data?.data?.event_end_date));
        setBookingStartDate(moment(data?.data?.booking_start_date));
        setCenter(data.data.center_id._id)
        setCourse(data.data.course);
        setIsFree(data?.data?.is_free)
        setLoading(false)
      },
      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    getAllSyallbus()

  }, [course])
  const getCenters = () => {
    request({
      url: api.list + `?search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: (data) => {
        // setLoading(false);
        setCenterList(data.data.list.docs);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.list.totalDocs,
        }));
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getCenters(pagination);
    getEditData()
  }, [refresh, debouncedSearchText]);
  const onCreate = (values) => {
    const { name,
       email,
       bookingNow,
       payFast,
       youGet,
        mode, syllabus, start_date, end_date, booking_start_date, booking_end_date, event_end_date, event_start_date, booking_fees, total_seat, event_type, course } = values
    if (editorValue === "" || !editorValue || editorValue === "<p></p>\n") {
      return ShowToast("Please add the description", Severty.ERROR);
    }
    setLoading(true)
    const payload = {}
    payload.name = name;
    payload.type = "Preparation";
    payload.description = editorValue
    payload.mode = mode
    payload.email = email
    payload.start_date = start_date
    payload.end_date = end_date
    payload.booking_start_date = booking_start_date
    payload.booking_end_date = booking_end_date
    payload.event_end_date = event_end_date
    payload.event_start_date = event_start_date
    payload.total_seat = total_seat
    payload.is_free = isFree
    payload.booking_fees = booking_fees;
    payload.bookingNow = bookingNow;
    payload.payFast = payFast;
    payload.youGet = youGet;
    payload.tnc = tnc
    payload.tnc_description = editorValue2
    payload.image = image && image.length > 0 ? image[0].url : '';
    payload.latitude = latLong.lat ? latLong.lat : null;
    payload.longitude = latLong.lng ? latLong.lng : null;
    payload.location = userAddress ? userAddress : null;
    payload.city = cityId ? cityId : null;
    payload.event_type = event_type ? event_type : null;
    payload.center_id = center;
    payload.course = course;
    payload.syllabus = syllabus;
    request({
      url: `${id ? api.addEdit + "/" + id : api.addEdit}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data?.status) {
          ShowToast(data.message, Severty.SUCCESS);
          // window.location.href("/preparation")
          navigate('/preparation')
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  const handleCancel = () => {
    navigate('/preparation')
  }
  const calculateNormalPrice = () => {
    const values = form.getFieldsValue();
    const bookingFee = values.booking_fees;
    const lateBookingFee = values.booking_fees;
    const superLateBookingFee = values.booking_fees;
    const youGet = values.youGet;
    const lateyouGet = values.youGet;
    const superLateyouGet = values.youGet;

    if (priceSuggestionValue === "1") {
      if (bookingFee) {
        const computedValues = computeBasedOnBookingFee(bookingFee);
        form.setFieldsValue({
       
            youGet: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
        
        });
      }
      if (superLateBookingFee) {
        const computedValues = computeBasedOnBookingFee(superLateBookingFee);
        form.setFieldsValue({
       
            youGet: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
     
        });
      }
      if (lateBookingFee) {
        const computedValues = computeBasedOnBookingFee(lateBookingFee);
        form.setFieldsValue({
      
            youGet: computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
      
        });
      }
    }
    else {
      if (youGet) {
        const computedValues = computeBasedOnCenterFee(youGet);
        form.setFieldsValue({
       
            booking_fees : computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
       
        });
      }
      if (lateyouGet) {
        const computedValues = computeBasedOnCenterFee(lateyouGet);
        form.setFieldsValue({
      
            booking_fees : computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
         
        });
      }
      if (superLateyouGet) {
        const computedValues = computeBasedOnCenterFee(superLateyouGet);
        form.setFieldsValue({
       
            booking_fees : computedValues.bookingCenterAmount,
            bookingNow: computedValues.bookingBooknowAmount,
            payFast: computedValues.bookingPayFastFeeAmount,
       
        });
      }
    }
  };


  const computeBasedOnBookingFee = (bookingFee) => {
    let bookingFeeWithoutPayFast = (bookingFee - ExamFeeConstant.PAY_FAST_OFFSET_CHARGE_EXTRA) / ExamFeeConstant.PAY_FAST_CHARGE;
    bookingFeeWithoutPayFast = Math.round(bookingFeeWithoutPayFast * 100) / 100;
    let payFastFee = bookingFee - bookingFeeWithoutPayFast;
    payFastFee = Math.round(payFastFee * 100) / 100;
    let baseCenterAmount = bookingFeeWithoutPayFast - ExamFeeConstant.MINIMUM_EXAM_CHARGE_BOOKNOW;
    baseCenterAmount = Math.max(0, Math.round(baseCenterAmount * 100) / 100);
    return {
      bookingPayFastFeeAmount: parseFloat(payFastFee.toFixed(2)),
      bookingBooknowAmount: ExamFeeConstant.MINIMUM_EXAM_CHARGE_BOOKNOW,
      bookingCenterAmount: baseCenterAmount
    };
  }

  const computeBasedOnCenterFee = (centerAmount) => {
    console.log(centerAmount)
    let booknowCharge = ExamFeeConstant.MINIMUM_EXAM_CHARGE_BOOKNOW;

    let payFastCharge = percentage(ExamFeeConstant.PAY_FAST_PERCENTAGE, (centerAmount + booknowCharge)) + ExamFeeConstant.PAY_FAST_OFFSET_CHARGE_EXTRA;
    payFastCharge = Math.round(payFastCharge * 100) / 100;

    let bookingFee = centerAmount + booknowCharge + payFastCharge;
    bookingFee = Math.round(bookingFee * 100) / 100;
    return {
      bookingPayFastFeeAmount: payFastCharge,
      bookingBooknowAmount: booknowCharge,
      bookingCenterAmount: bookingFee
    };
  }


  const percentage = (percent, total) => {
    return ((percent / 100) * total);
  }
  const priceSuggestion = (value) => {
    setPriceSuggestionValue(value)
  }

  return (
    <>
      <div className=" ">
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title={sectionName + " Management"}
        >
          <Form
            id="create"
            style={{ padding: 7 }}
            layout="vertical"
            form={form}
            onFinish={onCreate}

          >
            <Row style={{ marginBottom: 30 }} gutter={[32, 16]}>
              <Col span={24} sm={24}>
                <Form.Item
                  className="category-elt mb-0"
                  name="center_id"
                  label="Select Centre"
                >
                  <Select
                    onChange={(val) => setCenter(val)}
                    placeholder="Select Centre"
                  >
                    {centerList.length > 0
                      ? centerList.map((item) => (
                        <option key={item._id} value={item._id}>
                          <span className="cap">{item.name}</span>
                        </option>
                      ))
                      : null}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {center ? (
              <Row gutter={[24, 16]}>
                <Col span={24} md={12}>
                  <Form.Item
                    className="category-elt mb-0"
                    label={`Name`}
                    name="name"
                  >
                    <Input placeholder="Enter Name" />
                  </Form.Item>
                </Col>

                <Col span={24} md={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    name="event_type"
                    label="Select  Type"
                  >
                    <Select placeholder="Select Type">
                      {eventType.map((item, index) => (
                        <option key={item.value} value={item.value}>
                          <span className="cap">{item.label}</span>
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    name="mode"
                    label="Select Mode"
                  >
                    <Select placeholder="Select Mode">
                      {eventMode.map((item, index) => (
                        <option key={item.value} value={item.value}>
                          <span className="cap">{item.label}</span>
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} md={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    name="course"
                    label="Select Course"
                  >
                    <Select
                      onChange={(val) => setCourse(val)}
                      placeholder="Select Course"
                    >
                      {courseData.length > 0
                        ? courseData.map((item) => (
                          <option key={item._id} value={item._id}>
                            <span className="cap">{item.name}</span>
                          </option>
                        ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} md={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    name="syllabus"
                    label="Select Syallbus"
                  >
                    <Select placeholder="Select Syallbus">
                      {syllabusData && syllabusData.length > 0
                        ? syllabusData.map((item) => (
                          <option key={item._id} value={item._id}>
                            <span className="cap">{item.name}</span>
                          </option>
                        ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} sm={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    label="Preparation Start Date"
                    name="event_start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Preparation Start Date",
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD HH:mm"
                      showTime
                      placeholder="Enter Preparation Start Date"
                      onChange={(date) => setEventStartDate(date)} // Set the Event Start Date when selected
                      disabledDate={(current) =>
                        current && current < moment().startOf("minute")
                      } // Disable past dates
                    />
                  </Form.Item>
                </Col>

                {/* Event End Date */}
                <Col span={24} sm={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    label="Preparation End Date"
                    name="event_end_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Preparation End Date",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value > eventStartDate) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Event End Date must be after Event Start Date"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD HH:mm"
                      showTime
                      placeholder="Enter Preparation End Date"
                      disabledDate={disabledEventEndDate} // Custom validation for Event End Date
                      onChange={(date) => setEventEndDate(date)} // Set Event End Date
                    />
                  </Form.Item>
                </Col>

                {/* Booking Start Date */}
                <Col span={24} sm={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    label="Booking Start Date"
                    name="booking_start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Booking Start Date",
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder="Enter Booking Start Date"
                      disabledDate={disabledBookingStartDate} // Custom validation for Booking Start Date
                      onChange={(date) => setBookingStartDate(date)} // Set Booking Start Date
                    />
                  </Form.Item>
                </Col>

                {/* Booking End Date */}
                <Col span={24} sm={6}>
                  <Form.Item
                    className="category-elt mb-0"
                    label="Booking End Date"
                    name="booking_end_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Booking End Date",
                      },
                    ]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder="Enter Booking End Date"
                      disabledDate={disabledBookingEndDate} // Custom validation for Booking End Date
                    />
                  </Form.Item>
                </Col>
                {/* 
                   <Col span={24} md={6}>
                     <Form.Item
                       className=" category-elt mb-0"
                       label={`Booking Start Date`}
                       name="booking_start_date"
                     >
                       <Input
                         type="date"
                         name="booking_start_date"
                         placeholder="Enter Booking Start Date"
                       />
                     </Form.Item>
                   </Col>
   
                   <Col span={24} md={6}>
                     <Form.Item
                       className=" category-elt mb-0"
                       label={`Booking End Date`}
                       name="booking_end_date"
                     >
                       <Input
                         type="date"
                         name="end_date"
                         placeholder="Enter Booking End Date"
                       />
                     </Form.Item>
                   </Col>
   
                   <Col span={24} md={6}>
                     <Form.Item
                       className="  category-elt mb-0"
                       label={`Preparation Start Date`}
                       name="event_start_date"
                     >
                       <Input
                         type="date"
                         name="event_start_date"
                         placeholder="Enter Preparation Start Date"
                       />
                     </Form.Item>
                   </Col>
   
                   <Col span={24} md={6}>
                     <Form.Item
                       className=" category-elt mb-0"
                       label={`Preparation End Date`}
                       name="event_end_date"
                     >
                       <Input
                         type="date"
                         name="event_end_date"
                         placeholder="Enter Preparation End Date"
                       />
                     </Form.Item>
                   </Col> */}

                <Col span={24} md={6}>
                  <Form.Item
                    className="mb-0"
                    label={`Email`}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        max: 255,
                        message: "Email address not more then 255 characters!",
                      },
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input autoComplete="off" placeholder={`Enter Email`} />
                  </Form.Item>
                </Col>

                <Col span={24} md={6}>
                  <Form.Item
                    className="mb-0"
                    label={`Total Seats`}
                    name="total_seat"
                    rules={[
                      {
                        required: true,
                        message: "Total Seats must be provided!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      autoComplete="off"
                      placeholder={`Enter Total Seats`}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    label={
                      "Upload Event image for Web (Size [width x height] : 1130 x 640)"
                    }
                    name="image"
                  >
                    <SingleImageUpload
                      value={image}
                      fileType={FileType}
                      imageType={"banner"}
                      btnName={"Thumbnail"}
                      onChange={(data) => handleImage(data)}
                    />
                  </Form.Item>
                  {file && file.length > 0 && (
                    <div className="mt-2">
                      {" "}
                      <Image
                        width={120}
                        src={
                          file && file.length > 0 && file !== ""
                            ? file
                            : notfound
                        }
                      ></Image>{" "}
                    </div>
                  )}
                </Col>

                <Col span={24} md={6}>
                  <Form.Item
                    name="is_free"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <label>Is Free Event?</label>
                    <br />
                    <Switch
                      style={{ marginTop: 12 }}
                      checked={isFree}
                      onChange={handleIsFreeChange}
                    />
                  </Form.Item>
                </Col>

                {!isFree ? (
                  <>                 
                  <Col span={24} md={6}>
                    <Form.Item
                      className="mb-0"
                      label={`Booking Fees (${PRICE})`}
                      name="booking_fees"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Booking Fees must be provided!",
                      //   },
                      // ]}
                      // onChange={calculateNormalPrice}
                    >
                      <InputNumber
                        min={0}
                        disabled={true}
                        autoComplete="off"
                        placeholder={`Enter Booking Fees`}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                                  <Form.Item
                                    className="mb-0"   
                                    label={`You Get (${PRICE})`}
                                    name="youGet"
               
                                    onChange={calculateNormalPrice}

                                    rules={[
                                      { required: true, message: "Centre Amount is mandatory" },
                                    ]}
                                  >
                                    <InputNumber
                                      min={0}
                                   
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className="mb-0"
                                    label={`Book Now Fee (${PRICE})`}
                                    name="bookingNow"
               
                                   //  name={["normal", "bookingNow"]}
                                  >
                                    <InputNumber min={0} disabled />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    className="mb-0"
                                    label={`Pay Fast Fee (${PRICE})`}
                                    name="payFast"
               
                                   //  name={["normal", "payFast"]}
                                  >
                                    <InputNumber min={0} disabled />
                                  </Form.Item>
                                </Col>
                  </>
 
                ) : null}

                <Col span={24} md={24}>
                  <Form.Item
                    label={` Description`}
                    rules={[{ required: true, message: `Enter  Description` }]}
                  >
                    <DescriptionEditor
                      value={editorValue}
                      placeholder={`Enter  Description`}
                      onChange={(data) => handleEditorChange(data)}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={4}>
                  <Form.Item
                    name="tnc"
                    className="aligin-center"
                    valuePropName="checked"
                  >
                    <div
                      style={{ display: "", justifyContent: "space-between" }}
                    >
                      <label>Do you want to set terms and conditions?</label>
                      <br />
                      <Switch checked={tnc} onChange={handleTncChange} />
                    </div>
                  </Form.Item>
                </Col>

                {tnc ? (
                  <Col span={24} md={24}>
                    <Form.Item
                      label={`Terms & Conditions`}
                      rules={[
                        { required: true, message: `Enter Terms & Conditions` },
                      ]}
                    >
                      <DescriptionEditor
                        value={editorValue2}
                        placeholder={`Enter Terms & Conditions`}
                        onChange={(data) => handleEditorChange2(data)}
                      />
                    </Form.Item>
                  </Col>
                ) : null}

                <Row style={{ padding: 15 }} gutter={[24, 16]}>
                  <Col span={24} md={24}>
                    <Form.Item
                      label="Location (Drag Marker for Selecting Location)"
                      name="location"
                    >
                      <LocationMap
                        className="mt-3"
                        onChange={handleLocationChange}
                        userData={data && data}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Col span={24}>
                  <div className="card-btn">
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button htmlType="submit">Save Exam Preparation</Button>
                  </div>
                </Col>

                {/* <Col span={24}>
                     <div className="card-btn">
                       <Button>Cancel</Button>
                       <Button>Add Event</Button>
                     </div>
                   </Col> */}
              </Row>
            ) : null}
          </Form>
        </Card>
      </div>
    </>
  );
}

export default Index;
